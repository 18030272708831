.tcd-sidebar {
  background: white;
  border: 1px solid #d2d2d2;
  .widget {
    padding: 20px;
    border-bottom: 1px solid #d2d2d2;
    &:last-of-type {
      border-bottom: 0;
    }
    h3 {
      margin-top: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #d2d2d2;
      color: #373a3c;
    }
    ul {
      padding-left: 20px;
      margin-bottom: 0;
      li {
        a {
          color: $brand-primary;
          &:hover {
            color: #2e282b;
            text-decoration: none;
          }
        }
      }
    }
  }
}