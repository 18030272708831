p {
  &.no-margin {
    margin: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
}

blockquote {
  margin: 0 0 20px 0;
  border-left: 5px solid $brand-primary;
  padding: 20px 0 10px 45px;
  font-size: 28px;
  font-weight: 300;
  color: $brand-dark;
  line-height: 1.2em;
  p {
    line-height: 1.2em !important;
  }
}

h6 {
  font-family: "Noto Serif", serif;
  color: $brand-primary;
  font-style: italic;
  margin-bottom: 0;
}

main {
  padding-top: 60px;
  color: $brand-gray;
  h2 {
    font-size: 28px;
    font-weight: 300;
    color: $brand-dark;
    margin: 20px 0 16px 0;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $brand-primary;
    margin: 20px 0 16px 0;
  }
  h4 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $brand-dark;
    margin: 20px 0 16px 0;
  }
  p, ul, ol, li {
    line-height: 1.6em;
  }
}

.disclaimer {
  color: #ffffff;
  font-family: "Noto Serif", serif;
  font-size: 11px;
  font-style: italic;
  letter-spacing: 1px;
}

.front-hero {
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .overlay {
    background: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .inner {
    padding-top: 215px;
    position: relative;
    z-index: 2;
    .hero-intro {
      color: #ffffff;
      font-family: "Noto Serif", serif;
      font-style: italic;
      letter-spacing: 1px;
    }
    .hero-text {
      font-size: 4.5em;
      color: #ffffff;
      font-weight: 300;
    }
    .hero-subtext {
      font-size: 1.5em;
      color: #ffffff;
      font-weight: 300;
      line-height: 1.2em;
      margin-top: 10px;
      margin-bottom: 35px;
    }
  }
}

.quote {
  min-height: 128px;
  background: $brand-primary-dark;
  .inner {
    padding-top: 40px;
    .quote-text {
      color: #fff;
      font-family: Noto Serif, serif;
      font-style: italic;
      letter-spacing: 1px;
      font-size: 18px;
    }
    .quote-person {
      color: #c8e89c;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}


.heading-line {
  position: relative;
  font-size: 38px;
  font-weight: 300;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  &:before, &:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 144px;
    height: 1px;
    content: '\a0';
    background-color: #d2d2d2;
  }
  &:before {
    margin-left: -170px;
    text-align: right;
  }
  &:after {
    margin-left: 25px;
    text-align: left;
  }
}

.heading-line-o {
  position: relative;
  font-size: 38px;
  font-weight: 300;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  &:before, &:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 144px;
    height: 1px;
    content: '\a0';
    background-color: #ffffff;
  }
  &:before {
    margin-left: -170px;
    text-align: right;
  }
  &:after {
    margin-left: 25px;
    text-align: left;
  }
}

.culture {
  h2 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 35px;
    margin-bottom: 15px;
    color: $brand-dark;
  }
  img {
    margin: 0 auto;
    width: auto;
    height: 107px;
  }
  p {
    color: #6e6e6e;
  }
}
.page-banner {
  height: 430px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 40%;
  position: relative;
  overflow: hidden;
  .overlay {
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .fineprint {
    color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px;
  }
  .inner {
    position: relative;
    z-index: 2;
    .breadcrumb-container {
      padding-top: 130px;
      padding-bottom: 20px;
      float: left;
      width: 100%;
      overflow: hidden;
      position: relative;
      ul {
        clear: left;
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        left: 50%;
        text-align: center;
        &#breadcrumbs {
          li:first-of-type {
            a {
              border-top-left-radius: 30px;
              border-bottom-left-radius: 30px;
              padding: 0 10px 0 20px;
              &:before {
                display: none;
              }
            }
          }
          li {
            display: block;
            float: left;
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            right: 50%;
            a {
              color: #FFF;
              display: block;
              float: left;
              background: rgba(141, 198, 63, 0.5);
              text-decoration: none;
              text-transform: uppercase;
              position: relative;
              width: 70px;
              height: 30px;
              line-height: 30px;
              padding: 0 10px;
              text-align: center;
              margin-right: 15px;
              font-size: 10px;
              font-weight: 400;
              &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 15px solid rgba(141,198,63,.5);
                border-bottom: 15px solid rgba(141,198,63,.5);
                border-left: 10px solid transparent;
                position: absolute;
                top: 0;
                left: -10px;
                z-index: 1;
              }
              &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 10px solid rgba(141, 198, 63, 0.5);
                position: absolute;
                top: 0;
                left: 100%;
                z-index: 2;
              }
              &:hover {
                background: $brand-primary;
                &:before {
                  border-top-color: $brand-primary;
                  border-bottom-color: $brand-primary;
                }
                &:after {
                  border-left-color: $brand-primary;
                }
              }
            }
            .breadcrumb_last {
              color: #FFF;
              display: block;
              float: left;
              background: rgba(141, 198, 63, 0.5);
              text-decoration: none;
              text-transform: uppercase;
              position: relative;
              width: 100%;
              min-width: 50px;
              height: 30px;
              line-height: 30px;
              padding: 0 5px;
              text-align: center;
              margin-right: 15px;
              font-size: 10px;
              font-weight: 400;
              &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 15px solid rgba(141,198,63,.5);
                border-bottom: 15px solid rgba(141,198,63,.5);
                border-left: 10px solid transparent;
                position: absolute;
                top: 0;
                left: -10px;
                z-index: 1;
              }
              &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 10px solid rgba(141, 198, 63, 0.5);
                position: absolute;
                top: 0;
                left: 100%;
                z-index: 2;
              }
            }
          }
        }
      }
    }
    h1 {
      color: #ffffff;
      font-weight: 300;
      font-size: 64px;
      padding-top: 15px;
      margin: 0;
      padding: 0;
    }
  }
}
.related-posts {
  background: #eeeeee;
  padding: 80px 0 60px 0;
  .heading-line {
    color: $brand-dark;
  }
  .section-posts {
    margin-top: 40px;
  }
}
.section-posts {
  margin-top: 60px;
}
.su-spoiler-title {
  font-size: 16px !important;
  color: #8dc63f;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .front-hero {
    .inner {
      padding-top: 80px;
    }
  }
}
@media screen and (max-width: 360px) {
  .front-hero {
    .inner {
      padding-top: 40px;
      .hero-text {
        line-height: 1.2em;
      }
    }
  }
  .quote {
    .inner {
      padding-top: 15px;
    }
  }
  .heading-line, .heading-line-o {
    &:before, &:after {
      display: none;
    }
  }
  .page-banner {
    min-height: 100%;
    height: 100%;
    padding-bottom: 100px;
    .inner {
      .breadcrumb-container {
        padding-top: 80px;
      }
      h1 {
        font-size: 48px;
      }

    }
  }
}