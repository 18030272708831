header {
  #topbar {
    font-family: $font-family-serif;
    min-height: 48px;
    background: $brand-dark;
    color: rgba(255,255,255,0.5);
    letter-spacing: 1px;
    padding: 13px;
  }
  #headerbar {
    height: 143px;
    background-color: #ffffff;
    nav {
      padding-top: 0;
      padding-bottom: 0;
      .navbar-header {
        display: inline-block;
        a.brand {
          img {
            margin-top: 30px;
            margin-bottom: 30px;
          }
        }
      }
      .header-right {
        display: block;
        float: right;
        button {
          float: right;
          font-size: 28px;
        }
        .pills {
          margin-top: 50px;
          margin-right: 10px;
        }
        .mobile-phone {
          display: none;
        }
        @media screen and (min-width: 1180px) {
          button {
            display: none;
          }
        }
        @media screen and (max-width: 1180px) {
          button {
            display: inline-block;
            margin-top: 50px;
            margin-left: 10px;
          }
          .pills {
            display: inline-block;
          }
        }
        @media screen and (max-width: 700px){
          .pills {
            display: none;
          }
          .mobile-phone {
            display: inline-block;
            margin-top: 60px;
            margin-right: 10px;
          }
        }
      }
      .header-menu {
        display: block;
        float: right;
        #tcd-navbar {
          margin-right: 60px;
          ul#menu-top-menu {
            > li {
              float: left;
              min-width: 90px;
              height: 143px;
              text-align: center;
              position: relative;
              &.active {
                background: $brand-primary;
                > a {
                  color: #ffffff;
                  &:hover {
                    color: $brand-dark;
                  }
                }
                &:after {
                  content: '';
                  position: absolute;
                  bottom: -4px;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  background: rgba(141, 198, 63, 0.5);
                }
              }
              &:hover, &:focus {
                background: #f9f9f9;
              }
              &.menu-item-has-children {
                > a:before {
                  content: "\f107";
                  font-family: FontAwesome;
                  font-style: normal;
                  font-weight: normal;
                  text-decoration: inherit;
                  font-size: 18px;
                  position: absolute;
                  bottom: 40px;
                  left: 42%;
                }
              }
              &.menu-item-has-children:hover  {
                a {
                  color: $brand-dark;
                }
                > ul.sub-menu {
                  display: block;
                }
              }
              &.menu-item-has-children:before {
                content: "";
                display: block;
                height: 20px;
                position: absolute;
                bottom: -24px;
                width: 100%;
              }
              &:hover:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 4px;
                background: $brand-primary;
              }
              > a {
                width: 100%;
                height: 100%;
                display: block;
                color: $brand-dark;
                padding: 60px 20px 0 20px;
                text-decoration: none;
                font-size: 13px;
                text-transform: uppercase;
                letter-spacing: 1px;
                &:hover, &:focus {
                  color: $brand-dark;
                  background: #f9f9f9;
                }
              }
              ul {
                &.sub-menu {
                  display: none;
                  position: absolute;
                  background: #ffffff;
                  border: none;
                  width: 300px;
                  min-height: 49px;
                  left: 49%;
                  top: 164px;
                  transform: translateX(-50%);
                  list-style-type: none;
                  padding: 0;
                  > li {
                    height: 49px;
                    border-bottom: 1px solid #ededed;
                    &:hover, &:focus {
                      background: #f9f9f9;
                    }
                    &:last-child {
                      border-bottom: none;
                    }
                    > a {
                      display: block;
                      width: 100%;
                      height: 100%;
                      font-size: 13px;
                      padding: 15px;
                      text-decoration: none;
                      text-transform: uppercase;
                      letter-spacing: 1px;
                      color: $brand-dark;
                    }
                  }
                }
                &.sub-menu:after, &.sub-menu:before {
                  bottom: 100%;
                  left: 50%;
                  border: solid transparent;
                  content: " ";
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                }

                &.sub-menu:after {
                  border-color: rgba(255, 255, 255, 0);
                  border-bottom-color: #ffffff;
                  border-width: 10px;
                  margin-left: -10px;
                }
                &.sub-menu:before {
                  border-color: rgba(255, 255, 255, 0);
                  border-bottom-color: #ffffff;
                  border-width: 10px;
                  margin-left: -10px;
                }
              }
            }
          }

        }
        @media screen and (min-width: 1180px){
          #tcd-navbar {
            display: block;
          }
        }
        @media screen and (max-width: 1370px) {
          #tcd-navbar {
            margin-right: 0;
            ul#menu-top-menu {
              > li {
                min-width: 45px;
                > a {
                  padding: 60px 10px 0;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1180px) {
      nav {
        background: #ffffff;
        .header-menu {
          float: none;
          margin-left: -30px;
          margin-right: -30px;
          #tcd-navbar {
            margin-right: 0;
            ul#menu-top-menu {
              > li {
                float: none;
                height: 100%;
                > a {
                  padding: 20px 0;
                }
                &.menu-item-has-children {
                  > a {
                    &:before {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: inherit;
                      right: 6px;
                      width: 60px;
                      height: 60px;
                      padding: 10px;
                      font-size: 28px;
                      border-left: 1px solid #eeeeee;
                    }
                  }
                  &:hover {
                    > ul.sub-menu {
                      display: none;
                    }
                  }
                }
                ul {
                  &.sub-menu {
                    display: none;
                    position: relative;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    transform: initial;
                    top: 0;
                    border-top: 1px solid #eeeeee;
                    border-bottom: 1px solid #eeeeee;
                    > li {
                      border-bottom: 0;
                    }
                    &:before, &:after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 360px){
      nav {
        .header-right {
          button {
            position: absolute;
            top: 80px;
            right: 0;
            margin-top: 0;
          }
          .mobile-phone {
            position: absolute;
            top: 40px;
            right: 0;
            margin-right: 15px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
