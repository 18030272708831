.blog-post {
  position: relative;
  .blog-thumbnail {
    position: absolute;
    top: 20px;
    .blog-date {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      background: $brand-primary;
      color: #ffffff;
      text-align: center;
      overflow: hidden;
      .day {
        font-size: 24px;
        display: block;
        margin-top: 5px;
      }
      .month {
        font-size: 13px;
        text-transform: uppercase;
        display: block;
        margin-top: -30px;
      }
    }
  }
  .blog-panel {
    width: 100%;
    max-width: 450px;
    height: 295px;
    background: #ffffff;
    float: right;
    padding: 30px 30px 30px 40%;
    margin-bottom: 38px;
    word-wrap: break-word;
    h2 {
      &.entry-title {
        margin: 0 0 20px 0;
        font-size: 28px;
        font-weight: 300;
        a {
          color: $brand-dark;
          &:hover, &:focus {
            color: $brand-primary;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .blog-post {
    .blog-thumbnail {
      position: relative;
      top: 0;
      a {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .blog-panel {
      height: 100%;
      padding: 30px 30px 30px 30px;
      max-width: 100%;
      float: none;
    }
  }
}