*:hover {
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}
::selection {
  background: $brand-primary; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $brand-primary; /* Gecko Browsers */
}
.homequote {
  color: #000000;
  font-size: 20px;
  font-weight: 300;
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  &:before {
    content: '“';
    position: absolute;
    top: -8px;
    left: -30px;
    font-family: Noto Serif,serif;
    font-size: 40px;
  }
  &:after {
    content: '“';
    position: absolute;
    bottom: -25px;
    right: 0;
    font-family: Noto Serif,serif;
    font-size: 40px;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
}
.nav-center {
  text-align: center;
  font-size: 0;
  .center-tab {
    float: none !important;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    margin-left: 0 !important;
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-bottom: 0;
    &:last-of-type {
      border-right: 1px solid #d2d2d2;
    }
    .nav-link {
      width: 232px;
      height: 196px;
      color: rgba(2, 0, 0, 0.4);
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: 5px solid transparent;
      &.active {
        color: #2e282b;
        border: 0;
        border-top: 5px solid $brand-primary;
        border-bottom: 1px solid transparent;
        .tab-img {
          background-repeat: no-repeat;
          background-position: 50% 35%;
          &.tab-online {
            background-image: url('../images/laptop-a.png');
            &:hover, &:focus {
              background-image: url('../images/laptop-a.png');
            }
          }
          &.tab-workshops {
            background-image: url('../images/gear-a.png');
            &:hover, &:focus {
              background-image: url('../images/gear-a.png');
            }
          }
          &.tab-reporting {
            background-image: url('../images/chart-a.png');
            &:hover, &:focus {
              background-image: url('../images/chart-a.png');
            }
          }
          &.tab-strategy {
            background-image: url('../images/compass-a.png');
            &:hover, &:focus {
              background-image: url('../images/compass-a.png');
            }
          }
          &.tab-manage {
            background-image: url('../images/clipboard-a.png');
            &:hover, &:focus {
              background-image: url('../images/clipboard-a.png');
            }
          }
        }
      }
      &:hover {
        color: #2e282b;
      }
      .tab-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 35%;
        .tab-title {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 100%;
          height: 85%;
        }
        &.tab-online {
          background-image: url('../images/laptop-d.png');
          &:hover, &:focus {
            background-image: url('../images/laptop-h.png');
          }
        }
        &.tab-workshops {
          background-image: url('../images/gear-d.png');
          &:hover, &:focus {
            background-image: url('../images/gear-h.png');
          }
        }
        &.tab-reporting {
          background-image: url('../images/chart-d.png');
          &:hover, &:focus {
            background-image: url('../images/chart-h.png');
          }
        }
        &.tab-strategy {
          background-image: url('../images/compass-d.png');
          &:hover, &:focus {
            background-image: url('../images/compass-h.png');
          }
        }
        &.tab-manage {
          background-image: url('../images/clipboard-d.png');
          &:hover, &:focus {
            background-image: url('../images/clipboard-h.png');
          }
        }
      }
    }
  }
}
.tab-content-white {
  background: #ffffff;
  .tab-pane {
    padding: 90px 0;
    font-weight: 300;
    .pills {
      font-weight: 400;
    }
  }
}
.panel-grid {
  margin-right: 0 !important;
}
.circle {
  width: 335px;
  height: 335px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 34px;
  margin-right: 34px;
  position: relative;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 72px;
    height: 1px;
    background: #ffffff;
    top: 50%;
    right: -72px;
  }
  .inner {
    width: 305px;
    height: 305px;
    border-radius: 50%;
    background: #ffffff;
    margin: 14px;
    transition: all 0.6s ease;
    transform-style: preserve-3d;
    .front, .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }
    .front {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      .counter {
        font-family: 'Noto Serif', serif;
        font-size: 100px;
        color: $brand-dark;
        margin-top: -40px;
        margin-bottom: -20px;
      }
      .label {
        font-weight: 300;
        font-size: 24px;
        color: $brand-dark;
      }
      .standard {
        font-family: 'Noto Serif', serif;
        font-size: 14px;
        font-style: italic;
        text-transform: lowercase;
        color: $brand-primary;
        margin-top: -10px;
        letter-spacing: 1px;
      }
    }
    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
    &:hover {
      //transform: rotateY(180deg);
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
    .back {
      transform: rotateY(180deg);
    }
  }
}
.blog-page {
  background-color: #eeeeee;
}
.load-more, .back-to-blog {
  display: block;
  width: 100px;
  height: 100px;
  margin: 20px auto;
  border: 2px solid $brand-primary;
  border-radius: 50px;
  text-align: center;
  color: $brand-primary;
  padding: 30px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: transparent;
  outline: none;
  text-decoration: none;
  &:hover {
    background: $brand-primary;
    color: #ffffff;
    text-decoration: none;
  }
  &:focus {
    outline: none;
    text-decoration: none;
  }
}
.back-to-blog {
  padding: 30px 0;
}

.row-full{
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  height: 100%;
  margin-top: 100px;
  left: 50%;
}

*[id^='readlink'] {
  font-weight: bold;
  color: $brand-primary;
  padding: 0;
  border-bottom: 0 !important;
  &:hover, &:focus, &:active {
    font-weight: bold;
    color: $brand-primary;
    padding: 0;
    border-bottom: 0 !important;
    text-decoration: none;
  }
}

.owl-carousel {
  .owl-item{
    img {
      display: block;
      max-width: 100%;
      width: auto;
      margin: 0 auto;
      max-height: 150px;
    }
  }
}

.size-full {
  max-width: 100%;
}

@media screen and (max-width: 1200px){
  .circle {
    display: block;
    margin: 40px auto;
    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-right: auto;
      &:after {
        display: none;
      }
    }
    &:after {
      display: none;
    }
  }
}
@media screen and (max-width: 992px){
  .nav-center {
    .center-tab {
      .nav-link {
        width: 180px;
        height: 150px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .nav-center {
    .center-tab {
      .nav-link {
        width: 150px;
        height: 130px;
        .tab-img {
          background-position: 50% 50%;
          .tab-title {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .nav-center {
    .center-tab {
      .nav-link {
        width: 100px;
        height: 100px;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .nav-center {
    .center-tab {
      .nav-link {
        width: 50px;
        height: 50px;
        .tab-img {
          background-size: contain;
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .homequote {
    &:before, &:after {
      display: none;
    }
  }
}