
.pills {
  .pill-left  {
    background: #000000;
    width: 155px;
    height: 43px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 12px 12px 12px 26px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    font-size: 13px;
    letter-spacing: 1px;
    float: left;
    &:hover, &:focus {
      background: $brand-dark-light;
    }
  }
  .pill-right {
    background: $brand-primary;
    width: 155px;
    height: 43px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 12px 12px 12px 22px;
    color: #fff;
    text-decoration: none;
    display: block;
    font-size: 13px;
    letter-spacing: 1px;
    float: left;
    &:hover, &:focus {
      background: $brand-primary-dark;
    }
  }
  .hero-pill-black {
    width: 175px;
    height: 54px;
    background: $brand-dark;
    display: inline-block;
    border-radius: 30px;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 16px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 8px;
    &:hover, &:focus {
      background: $brand-dark-light;
    }
  }
  .hero-pill-green {
    width: 175px;
    height: 54px;
    background: $brand-primary;
    display: inline-block;
    border-radius: 30px;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 16px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 8px;
    text-align: center !important;
    &:hover, &:focus {
      background: $brand-primary-dark;
    }
  }
  .hero-pill-green-o {
    width: 175px;
    height: 54px;
    background: #ffffff;
    border: 2px solid $brand-primary;
    display: inline-block;
    border-radius: 30px;
    color: $brand-primary;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 16px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 8px;
    &:hover, &:focus {
      background: $brand-primary;
      color: #ffffff;
    }
  }
  .hero-pill-green-o-alt {
    width: 175px;
    height: 54px;
    background: none;
    border: 2px solid #ffffff;
    display: inline-block;
    border-radius: 30px;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 16px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 8px;
    &:hover, &:focus {
      background: #ffffff;
      color: $brand-primary;
    }
  }
  .pill-long {
    width: auto;
    min-width: 175px;
    padding: 16px 40px;
  }
}
.hero-pill-black {
  width: inherit;
  height: 54px;
  background: $brand-dark;
  display: inline-block;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 16px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 8px;
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
    background: $brand-dark-light;
  }
}
.hero-pill-green {
  width: inherit;
  height: 54px;
  background: $brand-primary;
  display: inline-block;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 16px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 8px;
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
    background: $brand-primary-dark;
  }
}
@media screen and (max-width: 360px){
  .pills {
    .hero-pill-black {
      margin-bottom: 20px;
    }
  }
}