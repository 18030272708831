// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// Contact forms
.subscribe-form {
  text-align: center;
  .wpcf7-form-control {
    margin: 20px 8px;
  }
  .wpcf7-text {
    max-width: 295px;
    width: 100%;
    height: 54px;
    background: #353333;
    border: none;
    border-radius: 30px;
    padding: 20px 40px;
    outline: none;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
  }
  .wpcf7-submit {
    width: 157px;
    height: 54px;
    background: $brand-primary;
    border: none;
    border-radius: 30px;
    outline: none;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    &:hover, &:focus {
      background: $brand-primary-dark;
    }
  }
  ::-webkit-input-placeholder {
    color: #ffffff;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #ffffff;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #ffffff;
  }

  :-ms-input-placeholder {
    color: #ffffff;
  }
  .wpcf7-response-output {
    color: #ffffff;
    margin: 0 0.5em 1em;
  }
  .wpcf7-not-valid-tip {
    top: -5px !important;
    left: 44px !important;
    border: 0 !important;
    border-radius: 2px;
    background: #353333 !important;
    padding: 5px !important;
  }
  img {
    &.ajax-loader {
      display: none;
    }
  }
}
.contact-form {
  .wpcf7-submit {
    width: 157px;
    height: 54px;
    background: $brand-primary;
    border: none;
    border-radius: 30px;
    outline: none;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    &:hover, &:focus {
      background: $brand-primary-dark;
    }
  }
}

@media screen and (max-width: 768px){
  .subscribe-form {
    .wpcf7-submit {
      display: block;
      margin: 20px auto;
    }
  }
}