footer {
  min-height: 194px;
  border-top: 1px solid #ededed;
  background: #f9f9f9;
  .footer-contact {
    padding: 75px 0 40px 0;
    min-height: 380px;
    background: $brand-dark;
    p {
      &.heading-line-o {
        color: #ffffff;
      }
    }
  }
  .footermenu-container {
    .footermenu {
      display: block;
      color: $brand-gray;
      font-size: 13px;
      ul {
        > li {
          float: left;
          text-align: center;
          padding-right: 20px;
          > a {
            color: $brand-gray;
            text-decoration: none;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 1px;
            &:hover, &:focus {
              color: $brand-primary;
            }
          }
        }
      }
    }
    .footermenu-sub {
      margin-top: 8px;
      display: block;
      color: $brand-gray;
      font-size: 13px;
      ul {
        > li {
          float: left;
          text-align: center;
          padding-right: 6px;
          border-right: 1px solid $brand-gray;
          &:not(:first-of-type) {
            padding-left: 6px;
          }
          &:last-of-type {
            padding-right: 0;
            border-right: none;
          }
          > a {
            color: $brand-gray;
            text-decoration: none;
            font-size: 13px;
            &:hover, &:focus {
              color: $brand-primary;
            }
          }
        }
      }
    }
  }
  .footerarea {
    min-height: 146px;
    padding: 50px 15px;
    .socials {
      display: inline-block;
      margin-right: 30px;
      float: right;
      > a {
        margin-left: 5px;
      }
      .social-twitter {
        display: inline-block;
        background-image: url('../images/twitter.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 44px;
        height: 43px;
        &:hover, &:focus {
          background-image: url('../images/twitter-h.png');
        }
      }
      .social-facebook {
        display: inline-block;
        background-image: url('../images/facebook.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 44px;
        height: 43px;
        &:hover, &:focus {
          background-image: url('../images/facebook-h.png');
        }
      }
      .social-linkedin {
        display: inline-block;
        background-image: url('../images/linkedin.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 43px;
        height: 43px;
        &:hover, &:focus {
          background-image: url('../images/linkedin-h.png');
        }
      }
      .social-paperli {
        display: inline-block;
        background-image: url('../images/paperli.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 43px;
        height: 43px;
        &:hover, &:focus {
          background-image: url('../images/paperli-h.png');
        }
      }
    }
    @media screen and (max-width: 1200px){
      .socials {
        margin-top: 30px;
      }
    }
  }
  .footerbar {
    min-height: 48px;
    background: $brand-dark;
    color: #6e6e6e;
    font-size: 10px;
    text-transform: uppercase;
    padding: 15px;
    letter-spacing: 1px;
    a {
      color: inherit;
      text-decoration: none;
      &:hover, &:focus {
        color: $brand-primary;
      }
    }
  }
}

@media screen and (max-width: 768px){
  footer {
    .footerarea {
      .pills {
        margin-top: 30px;
      }
      .socials {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 620px){
  footer {
    .footerarea {
      .socials {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 620px){
  footer {
    .footerarea {
      .socials {
        margin-top: 30px;
        float: left;
      }
    }
  }
}
